<template>
  <server-error
    :html-lines="$t('html', { returnObjects: true })"
    :title="$t('title')"
  />
</template>

<script>
import ServerError from '../components/ServerError.vue'

export default {
  name: 'ServerError503',

  components: {
    ServerError,
  },

  created() {
    this.$emit('hydrated')
    this.$emit('page-view')
  },

  beforeRouteUpdate(_to, _from, next) {
    this.$root.referrer = window.location.href
    next()
  },
}
</script>

<i18n locale="de">
{
  "html": [
    "<h2>",
      "Seite temporär nicht verfügbar",
    "</h2>",
    "<p>",
      "Entschuldigung, diese Seite ist temporär nicht verfügbar. Bitte versuchen Sie es in Kürze erneut.",
    "</p>"
  ],
  "title": "Seite temporär nicht verfügbar"
}
</i18n>

<i18n locale="en">
{
  "html": [
    "<h2>",
      "Page Temporarily Unavailable",
    "</h2>",
    "<p>",
      "Sorry, this page is temporarily unavailable. Please try again in a little while.",
    "</p>"
  ],
  "title": "Page Temporarily Unavailable"
}
</i18n>
