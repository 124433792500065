<template>
  <div
    id="app"
    class="d-flex flex-column"
  >
    <div
      class="p-0 w-100"
    >
      <app-navbar
        :language="language"
        :page="page"
        :supported-languages="supportedLanguages"
        @hydrated="navbarHydrated = true"
      />
    </div>
    <sticky-banner />
    <div
      class="d-flex flex-column flex-grow-1"
    >
      <router-view
        @hydrated="routerViewHydrated = true"
        @page-changed="page = $event"
      />
    </div>

    <app-footer
      :language="language"
      :page="page"
      :supported-languages="supportedLanguages"
      @hydrated="footerHydrated = true"
    />

    <ContactModal />
  </div>
</template>

<script>
import ApolloSSR from 'vue-apollo/ssr'
import AppFooter from './components/AppFooter.vue'
import AppNavbar from './components/AppNavbar.vue'
import ContactModal from './components/ContactModal.vue'
import i18next from 'i18next'
import StickyBanner from './components/StickyBanner.vue'

export default {
  components: {
    AppFooter,
    AppNavbar,
    ContactModal,
    StickyBanner,
  },

  data() {
    return {
      defaultLanguageCode: 'en',
      footerHydrated: false,
      language: null,
      navbarHydrated: false,
      page: null,
      routerViewHydrated: false,
      supportedLanguages: {
        de: { code: 'de', name: 'Deutsch' },
        en: { code: 'en', name: 'English' },
      },
    }
  },

  computed: {
    browserLanguages() {
      if (navigator.languages) return navigator.languages
      else if (navigator.userLanguage) return [navigator.userLanguage]
      else return []
    },
    currentPageLanguage() {
      return this.page && this.supportedLanguages[this.page.language]
    },
    currentPathLanguage() {
      const pathSegments = window.location.pathname.split('/')

      if (Object.keys(this.supportedLanguages).includes(pathSegments[1])) {
        return this.supportedLanguages[pathSegments[1]]
      } else {
        return null
      }
    },
    supportedBrowserLanguage() {
      const supportedBrowserLanguage = this.browserLanguages.find(browserLanguage =>
        Object.keys(this.supportedLanguages).find(supportedLanguage =>
          browserLanguage.toLowerCase().startsWith(supportedLanguage.toLowerCase()),
        ),
      )

      if (supportedBrowserLanguage) {
        return this.supportedLanguages[supportedBrowserLanguage.toLowerCase().slice(0, 2)]
      } else {
        return null
      }
    },
  },

  watch: {
    'footerHydrated'(newVal) {
      if (newVal) this.conditionallyEmitAllAppComponentsHydrated()
    },
    'page.language'() {
      this.setLanguage()
    },
    'navbarHydrated'(newVal) {
      if (newVal) this.conditionallyEmitAllAppComponentsHydrated()
    },
    'routerViewHydrated'(newVal) {
      if (newVal) this.conditionallyEmitAllAppComponentsHydrated()
    },
  },

  created() {
    this.setLanguage()

    this.$router.addRoutes([
      {
        path: '',
        redirect: () => `/${this.language.code}`,
      },
    ])
  },

  methods: {
    conditionallyEmitAllAppComponentsHydrated() {
      if (this.footerHydrated && this.navbarHydrated && this.routerViewHydrated) {
        if (this.$root.prerendering) {
          const scriptEl = document.createElement('script')
          scriptEl.appendChild(document.createTextNode(ApolloSSR.exportStates(this.$apollo.provider, {})))
          document.body.appendChild(scriptEl)
        }

        document.dispatchEvent(new Event('all-app-components-hydrated'))
      }
    },
    setLanguage() {
      this.language =
        this.currentPageLanguage ||
        this.currentPathLanguage ||
        this.supportedBrowserLanguage ||
        this.supportedLanguages[this.defaultLanguageCode]

      this.$store.commit('App/language', this.language)

      document.querySelector('html').setAttribute('lang', this.language.code)

      i18next.changeLanguage(this.language.code)
    },
  },
}
</script>

<style lang="scss">
body {
  background: #EFEFEF;
  min-height: 100vh;
}

#app {
  min-height: 100vh;
}
</style>
