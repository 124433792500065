<template>
  <b-modal
    :id="id"
    v-model="show"
    v-bind="modalOptions"
  >
    <slot>
      <!-- Using `v-html="body"` on `<b-modal />` itself doesn't work. -->
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="body" />
    </slot>
  </b-modal>
</template>

<script>
export default {
  name: 'Modal',

  props: {
    body: {
      default: null,
      type: String,
    },
    id: {
      required: true,
      type: String,
    },
    modalOptions: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      show: false,
    }
  },

  mounted() {
    document.addEventListener('click', this.onToggleClick, false)
  },

  destroyed() {
    document.removeEventListener('click', this.onToggleClick, false)
  },

  methods: {
    onToggleClick(ev) {
      for (var target = ev.target; target && target !== this; target = target.parentNode) {
        if (
          typeof target.matches === 'function' &&
          target.matches(`[data-toggle="modal"][data-target="#${this.id}"]`)
        ) {
          ev.preventDefault()
          this.show = !this.show
          break
        }
      }
    },
  },
}
</script>
