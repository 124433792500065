<template>
  <component
    :is="contentBlock.__typename"
    v-bind="contentBlock"
  />
</template>

<script>
import CardsRow from './CardsRow.vue'
import CoverCarousel from './CoverCarousel.vue'
import HtmlContainer from './HtmlContainer.vue'
import Modal from './Modal.vue'

export default {
  name: 'ContentBlock',

  components: {
    CardsRow,
    CoverCarousel,
    HtmlContainer,
    Modal,
  },

  props: {
    contentBlock: {
      required: true,
      type: Object,
    },
  },
}
</script>
