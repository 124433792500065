<template>
  <server-error
    :html-lines="$t('html', { returnObjects: true })"
    :title="$t('title')"
  />
</template>

<script>
import ServerError from '../components/ServerError.vue'

export default {
  name: 'ServerError404',

  components: {
    ServerError,
  },

  created() {
    this.$emit('hydrated')
    this.$emit('page-view')
  },

  beforeRouteUpdate(_to, _from, next) {
    this.$root.referrer = window.location.href
    next()
  },
}
</script>

<i18n locale="de">
{
  "html": [
    "<h2>",
      "Seite nicht gefunden",
    "</h2>",
    "<p>",
      "Entschuldigung, diese Seite konnte nicht gefunden werden.",
    "</p>",
    "<p>",
      "Bitte überprüfen Sie die Adresse; oder benutzen Sie die obere Navigationsleiste oder das folgende Menü, um eine",
      "andere Seite aufzusuchen.",
    "</p>"
  ],
  "title": "Seite nicht gefunden"
}
</i18n>

<i18n locale="en">
{
  "html": [
    "<h2>",
      "Page Not Found",
    "</h2>",
    "<p>",
      "Sorry, this page could not be found.",
    "</p>",
    "<p>",
      "Please review the address you have entered; or navigate to another page using the menu bar above or the full",
      "menu below.",
    "</p>"
  ],
  "title": "Page Not Found"
}
</i18n>
