<template>
  <footer class="text-light">
    <app-footer-menu
      :language="language"
      :page="page"
      :supported-languages="supportedLanguages"
      @hydrated="$emit('hydrated')"
    />

    <app-footer-credits
      :language="language"
    />
  </footer>
</template>

<script>
import AppFooterCredits from './AppFooterCredits.vue'
import AppFooterMenu from './AppFooterMenu.vue'

export default {
  name: 'AppFooter',

  components: {
    AppFooterCredits,
    AppFooterMenu,
  },

  props: {
    language: {
      required: true,
      type: Object,
    },
    page: {
      default: null,
      type: Object,
    },
    supportedLanguages: {
      required: true,
      type: Object,
    },
  },
}
</script>
