export default {
  namespaced: true,

  getters: {
    language(state) {
      return state.language
    },
  },

  mutations: {
    language(state, language) {
      state.language = language
    },
  },

  state: {
    language: null,
  },
}
