<template>
  <b-carousel
    id="carousel-fade"
    fade
    :indicators="carouselSlides.length >= 2 ? true : false"
  >
    <b-carousel-slide
      v-for="carouselSlide in sortedCarouselSlides"
      :key="carouselSlide.id"
    >
      <template #img>
        <div
          class="h-100 slide"
          :style="`background-image: url('${carouselSlide.imageSrc}');`"
        >
          <div class="slide__inner-container">
            <h3
              class="display-4 slide__heading text-center"
              style="line-height: 1;"
              :style="carouselSlide.buttonCaption ? 'margin-bottom: 2.5rem;' : 'margin-bottom: 0;'"
            >
              <span>
                {{ carouselSlide.heading }}
              </span>
            </h3>
            <div class="text-center">
              <b-button
                v-if="carouselSlide.buttonCaption"
                class="font-weight-bolder slide-button"
                :href="carouselSlide.buttonHref.startsWith('/') ? null : carouselSlide.buttonHref"
                :to="carouselSlide.buttonHref.startsWith('/') ? carouselSlide.buttonHref : null"
                variant="primary"
              >
                {{ carouselSlide.buttonCaption }}
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-carousel-slide>
  </b-carousel>
</template>

<script>
export default {
  name: 'CoverCarousel',

  props: {
    carouselSlides: {
      required: true,
      type: Array,
    },
  },

  computed: {
    sortedCarouselSlides() {
      return [...this.carouselSlides].sort((carouselSlideA, carouselSlideB) =>
        carouselSlideA.position - carouselSlideB.position,
      )
    },
  },
}
</script>

<style lang="scss" scoped>
  div.carousel-item {
    height: 40vh;
    min-height: 5rem;
    max-height: 400px;
  }

  .slide {
    background-position: center center;
    background-size: cover;
    display: flex;
    justify-content: center;

    &__inner-container {
      display: flex;
      flex: 0 0 70%;
      flex-direction: column;
      justify-content: center;
      padding: .1rem .2rem;
    }

    &__heading {
      color: #FFF;

      span {
        font-size: .8em;
      }
    }

    &__button {
      font-size: 2rem;
    }
  }
</style>
