<template>
  <html-container
    :css-classes="[]"
    :fluid="false"
    :html="htmlLines.join('\n')"
  />
</template>

<script>
import HtmlContainer from './HtmlContainer.vue'

export default {
  name: 'ServerError',

  components: {
    HtmlContainer,
  },

  props: {
    htmlLines: {
      required: true,
      type: Array,
    },
    title: {
      required: true,
      type: String,
    },
  },

  created() {
    document.title = this.title

    const params = (new URL(document.location)).searchParams
    const originalPath = params.get('originalPath')

    if (originalPath) history.replaceState(null, '', originalPath)
  },
}
</script>
