<template>
  <b-container
    class="bg-white html-container shadow py-3"
  >
    <b-row
      :cols="cardsPerRow.xs"
      :cols-lg="cardsPerRow.lg"
      :cols-md="cardsPerRow.md"
      :cols-sm="cardsPerRow.sm"
      :cols-xl="cardsPerRow.xl"
    >
      <b-col
        v-for="card in sortedCards"
        :key="card.id"
        class="mb-3"
      >
        <b-card
          no-body
          style="border: none;"
        >
          <b-card-header
            v-if="card.title || card.titleImg"
            header-class="p-0 position-relative"
          >
            <b-card-img-lazy
              v-if="card.titleImg"
              height="220"
              :src="card.titleImg"
            />
            <!-- eslint-disable vue/no-v-html -->
            <b-card-title
              v-if="card.title"
              class="m-0 text-primary"
              :class="{ 'card-img-overlay': card.titleImg }"
              title-tag="h3"
              v-html="card.title"
            />
            <!-- eslint-enable vue/no-v-html -->
          </b-card-header>

          <b-card-body
            v-if="card.body || card.readMoreBody"
          >
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="card.body"
              v-html="card.body"
            />
            <!-- eslint-enable vue/no-v-html -->

            <div
              v-if="card.readMoreBody"
            >
              <b-button
                v-b-toggle="`cardReadMoreCollapse-${card.id}`"
                small
                variant="primary"
              >
                {{ card.readMoreCaption || 'Read More' }}
              </b-button>

              <b-collapse
                :id="`cardReadMoreCollapse-${card.id}`"
                class="mt-3"
              >
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-html="card.readMoreBody" />
              </b-collapse>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'CardsRow',

  props: {
    cards: {
      required: true,
      type: Array,
    },
    cardsPerRow: {
      required: true,
      type: Object,
    },
  },

  computed: {
    sortedCards() {
      return [...this.cards].sort((cardA, cardB) =>
        cardA.position - cardB.position,
      )
    },
  },
}
</script>

<style lang="scss">
div[__typename="CardsRow"] {

  .card .card-body ul {
    max-width: 38rem;
    padding-left: 0;
    text-align: justify;

    li {
      border-left: 1px solid #ff4f00 !important;
      list-style: none !important;
      margin-bottom: 1.5rem !important;
      padding: 0 0 0 .6rem !important;
    }
  }

  .card-body {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom-left-radius: calc(0.25rem - 1px);
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-top: none !important;
  }

  .card-header {
    border-bottom: none;
  }

  .card-img {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    object-fit: cover;
  }
}
</style>
