<template>
  <b-navbar
    toggleable="lg"
    type="dark"
    variant="primary"
  >
    <b-navbar-brand
      :to="'/'"
      @click="closeOffCanvas"
    >
      <img
        alt="ganser.consulting GmbH"
        class="logo logo-lg"
        :src="require('../assets/Logo-Horizontal-White-Registered-Trademark-200x50-1.png')"
        style="max-height: 60px; transition: transform .3s ease-in-out .1s;"
        :style="isOffCanvasOpen ? 'transform: translateX(-220px);' : '' "
      >
    </b-navbar-brand>

    <b-navbar-toggle
      target="nav-collapse"
      @click.prevent="isOffCanvasOpen ? closeOffCanvas() : openOffCanvas()"
    >
      <div class="burger-icon burger-icon__top" />
      <div class="burger-icon burger-icon__middle" />
      <div class="burger-icon burger-icon__bottom" />
    </b-navbar-toggle>

    <div
      id="#background-filter"
      class="background-filter d-lg-none"
      :class="{ 'background-filter--hidden': !isOffCanvasOpen }"
      @click="closeOffCanvas()"
    />

    <b-collapse
      id="nav-collapse"
      ref="navCollapse"
      class="flex-grow-1"
      is-nav
    >
      <b-spinner
        v-if="$apollo.loading"
        :label="$t('Loading')"
        small
        type="grow"
        variant="light"
      />

      <app-navbar-menu
        v-else-if="menu"
        :language="language"
        :menu="menu"
        @navigated="closeOffCanvas"
      />

      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown id="language-dropdown">
          <template #button-content>
            <span class="d-lg-none mr-1">
              Language / Sprache
            </span>
            <img
              class="lang-flag-icon align-baseline"
              :src="require(`../assets/flags/${language.code}.svg`)"
              :title="language.name"
            >
          </template>

          <b-dropdown-item
            v-for="supportedLanguage in supportedLanguages"
            :key="supportedLanguage.code"
            :hreflang="supportedLanguage.code"
            :to="languageHref(supportedLanguage.code)"
            @click="closeOffCanvas"
          >
            <img
              class="lang-flag-icon mr-2"
              :src="require(`../assets/flags/${supportedLanguage.code}.svg`)"
              :title="supportedLanguage.name"
            >
            {{ supportedLanguage.name }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-button-group
          class="contact-button-group py-0"
        >
          <b-button
            class="font-weight-bold"
            :to="$t('/en/about-us/contact')"
            variant="info"
            @click="closeOffCanvas"
          >
            {{ $t('Contact') }}
          </b-button>
          <b-button
            class="cta--danger font-weight-bold px-4"
            :to="$t('/en/solutions/emergency-support')"
            variant="danger"
            @click="closeOffCanvas"
          >
            {{ $t('Emergency') }}
          </b-button>
        </b-button-group>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import gql from 'graphql-tag'
import AppNavbarMenu from './AppNavbarMenu.vue'

export default {
  name: 'AppNavbar',

  components: {
    AppNavbarMenu,
  },

  props: {
    language: {
      required: true,
      type: Object,
    },
    page: {
      default: null,
      type: Object,
    },
    supportedLanguages: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      menu: null,
      offCanvasOpen: false,
    }
  },

  apollo: {
    menu: {
      query: gql`
        query GetMenu($id: String!) {
          menu(id: $id) {
            menuItems {
              id
              label
              parentId
              position

              page {
                fullPath
                language
              }
            }
          }
        }
      `,
      result({ data }) {
        this.menuLoaded(data.menu)
      },
      variables: { id: 'main' },
    },
  },

  computed: {
    pageTranslations() {
      if (!this.page) return {}

      return Object.fromEntries(this.page.translations.map(pageTranslation =>
        [pageTranslation.language, pageTranslation.fullPath],
      ))
    },
    isOffCanvasOpen() {
      return !!this.offCanvasOpen
    },
  },

  methods: {
    closeOffCanvas() {
      this.offCanvasOpen = false
      document.body.style.overflow = ''
      this.$refs.navCollapse.show = false
    },
    menuLoaded(menu) {
      this.menu = menu
      this.$emit('hydrated')
    },
    openOffCanvas() {
      this.offCanvasOpen = true
      document.body.style.overflow = 'hidden'
      this.$refs.navCollapse.show = true
    },
    languageHref(languageCode) {
      if (this.page && this.page.language === languageCode) {
        return this.page.fullPath
      } else if (this.pageTranslations[languageCode]) {
        return this.pageTranslations[languageCode]
      } else {
        return `/${languageCode}`
      }
    },
  },
}
</script>

<style scoped lang="scss">
.cta--danger {
 background-color: rgb(185, 0, 50);
 border-color: rgb(185, 0, 50);

  &:hover, :focus {
    background-color: rgb(150, 0, 40);
    border-color: rgb(150, 0, 40);
  }
}

#language-dropdown::v-deep ul.dropdown-menu.show {
  min-width: 8.5rem !important;

  a {
    align-items: center;
    display: flex;
    padding: 0.25rem 1.5rem 0.25rem .5rem;
  }
}

.navbar-dark::v-deep .navbar-nav .nav-link {
  color: #FFF;
  font-weight: bold;
}
</style>

<i18n locale="de">
{
  "/en/about-us/contact": "/de/über-uns/kontakt",
  "/en/solutions/emergency-support": "/de/lösungen/notfallservice",
  "Contact": "Kontakt",
  "Emergency": "Notfall",
  "Loading": "Lade…"
}
</i18n>

<i18n locale="en">
{
  "/en/about-us/contact": "/en/about-us/contact",
  "/en/solutions/emergency-support": "/en/solutions/emergency-support",
  "Contact": "Contact",
  "Emergency": "Emergency",
  "Loading": "Loading…"
}
</i18n>
