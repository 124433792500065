import Vue from 'vue'

import {
  ButtonPlugin,
  ButtonGroupPlugin,
  CardPlugin,
  CarouselPlugin,
  CollapsePlugin,
  EmbedPlugin,
  ImagePlugin,
  LayoutPlugin,
  LinkPlugin,
  ModalPlugin,
  NavbarPlugin,
  SpinnerPlugin,
} from 'bootstrap-vue'
import '../assets/bootstrap.scss'

Vue.use(ButtonPlugin)
Vue.use(ButtonGroupPlugin)
Vue.use(CardPlugin)
Vue.use(CarouselPlugin)
Vue.use(CollapsePlugin)
Vue.use(EmbedPlugin)
Vue.use(ImagePlugin)
Vue.use(LayoutPlugin)
Vue.use(LinkPlugin)
Vue.use(ModalPlugin)
Vue.use(NavbarPlugin)
Vue.use(SpinnerPlugin)
