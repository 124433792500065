<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    id="stickyBanner"
    class="bg-dark sticky-banner text-light text-center"
    v-html="$t('banner content')"
  />
  <!-- eslint-enable vue/no-v-html -->
</template>

<script>
export default {
  mounted() {
    if('IntersectionObserver' in window) {
      const stickyBanner = document.getElementById("stickyBanner")
      const observer = new IntersectionObserver(
        ([e]) => e.target.classList.toggle("is-pinned", e.intersectionRatio < 1),
        { threshold: [1] },
      )

      observer.observe(stickyBanner)
    }
  },
}
</script>

<style lang="scss">
.is-pinned {
  background-color: var(--primary) !important;

  .btn {
    background-color: var(--dark);
    border-color: var(--dark);
  }
}

.sticky-banner {
  font-weight: 550;
  padding: 4px 5px 5px 5px;
  position: sticky;
  top: -1px;
  transition: background-color .25s linear;
  z-index: 10;
}
</style>

<i18n locale="de">
{
  "banner content": "<span style='text-decoration: underline;'>Coronavirus</span>: wir bleiben uneingeschränkt erreichbar und helfen Ihnen bei Ihrer Digitalisierung! <a class='btn font-weight-bold btn-info btn-sm py-0 ml-1' href='/de/über-uns/kontakt' data-toggle='modal' data-target='#ContactModal'>Kontakt</a>"
}
</i18n>

<i18n locale="en">
{
  "banner content": "<span style='text-decoration: underline;'>Coronavirus</span>: we continue to work without interruption and can assist you with your digital transformation! <a class='btn font-weight-bold btn-info btn-sm py-0 ml-1' href='/en/about-us/contact' data-toggle='modal' data-target='#ContactModal'>Contact Us</a>"
}
</i18n>
