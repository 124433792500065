<template>
  <b-nav
    vertical
  >
    <b-nav-item
      :link-classes="{ 'font-weight-bold': true, 'text-primary': true, 'px-0': true, 'py-1': true, disabled: !menuItem.link }"
      :to="menuItem.link && menuItem.page.fullPath || null"
    >
      {{ menuItem.label }}
    </b-nav-item>
    <b-nav-item
      v-for="childMenuItem in childMenuItems"
      :key="childMenuItem.id"
      link-classes="text-light p-0 py-1"
      :to="childMenuItem.page.fullPath"
    >
      {{ childMenuItem.label }}
    </b-nav-item>
  </b-nav>
</template>

<script>
export default {
  name: 'AppFooterSubmenu',

  props: {
    allMenuItems: {
      required: true,
      type: Array,
    },
    menuItem: {
      required: true,
      type: Object,
    },
  },

  computed: {
    childMenuItems() {
      return this.allMenuItems.filter(allMenuItem => allMenuItem.parentId === this.menuItem.id)
    },
  },
}
</script>
