<template>
  <modal
    id="ContactModal"
    :modal-options="modalOptions"
  >
    <div class="container-fluid">
      <div class="row row-cols-1 row-cols-lg-2">
        <div class="col mb-3 mb-lg-0 text-center text-lg-right">
          <a
            class="btn btn-primary font-weight-bolder text-light"
            :href="`mailto:${$t('hello@ganserDOTconsulting')}`"
          >
            <i class="fas fa-envelope-open-text mr-1" />
            {{ $t('hello@ganserDOTconsulting') }}
          </a>
        </div>

        <div class="col text-center text-lg-left">
          <a
            class="btn btn-primary font-weight-bolder text-light"
            href="tel:+4922179006734"
          >
            <i class="fas fa-phone-volume mr-1" />
            {{ $t('+49 221 790 067 34') }}
          </a>
        </div>
      </div>

      <div class="row justify-content-center mt-3">
        <div class="col col-12 col-lg-10">
          <p class="text-center">
            {{ $t('At our German head office, …', { day: currentHqWeekdayOrHolidayStr, time: currentHqTime.format('LT') }) }}
          </p>

          <p class="text-center">
            {{ $t('We are probably') }}
            <strong v-if="isHqOpen">{{ $t('open') }}</strong><strong v-else>{{ $t('closed') }}</strong>.
          </p>

          <p class="text-center">
            {{ $t('You can thus reach us') }}
            <strong>
              <span v-if="isHqOpen">
                {{ $t('sowohl') }}
                <a :href="`mailto:${$t('hello@ganserDOTconsulting')}`">{{ $t('by email') }}</a>
                {{ $t('as well as') }}
                <a href="tel:+4922179006734">{{ $t('by phone') }}</a>
              </span>

              <span v-else>
                {{ $t('best') }}
                <a :href="`mailto:${$t('hello@ganserDOTconsulting')}`">{{ $t('by email') }}</a>
              </span>
            </strong>.
          </p>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from './Modal'
import moment from 'moment-timezone'

export default {
  name: 'ContactModal',

  components: {
    Modal,
  },

  data() {
    return {
      currentHqTime: this.getCurrentHqTime(),
      hqHolidays: [
        moment.tz('2020-05-01', 'Europe/Berlin'),
      ],
      hqWorkdays: [1, 2, 3, 4, 5],
      hqOpeningHours: [9, 10, 11, 12, 13, 14, 15, 16, 17],
      updateCurrentHqTimeIntervalId: null,
    }
  },

  computed: {
    currentHqWeekdayOrHolidayStr() {
      return this.isHqWithinHolidays ? this.$t('local holiday') : this.currentHqTime.format('dddd')
    },
    isHqOpen() {
      return this.isHqWithinOpeningDays && this.isHqWithinOpeningHours
    },
    isHqWithinHolidays() {
      return !!this.hqHolidays.find(hqHoliday => hqHoliday.isSame(this.currentHqTime, 'day'))
    },
    isHqWithinOpeningDays() {
      return !this.isHqWithinHolidays && this.isHqWithinWorkday
    },
    isHqWithinOpeningHours() {
      return this.hqOpeningHours.includes(this.currentHqTime.hour())
    },
    isHqWithinWorkday() {
      return this.hqWorkdays.includes(this.currentHqTime.isoWeekday())
    },
    modalOptions() {
      return {
        centered: true,
        headerClass: 'border-0 text-primary',
        hideFooter: true,
        size: 'lg',
        title: this.$t('Contact Us'),
        titleClass: 'pl-5 pr-3 text-center w-100',
      }
    },
  },

  mounted() {
    this.updateCurrentHqTimeIntervalId = window.setInterval(() => {
      this.updateCurrentHqTime()
    }, 1000)
  },

  destroyed() {
    if (this.updateCurrentHqTimeIntervalId) window.clearInterval(this.updateCurrentHqTimeIntervalId)
  },

  methods: {
    getCurrentHqTime() {
      return moment().tz('Europe/Berlin').locale(this.$store.getters['App/language'].code)
    },
    updateCurrentHqTime() {
      this.currentHqTime = this.getCurrentHqTime()
    },
  },
}
</script>

<i18n locale="de">
{
  "Contact Us": "Kontaktieren Sie uns unverbindlich",
  "At our German head office, …": "In unserem Kölner Büro ist es aktuell {{time}} an einem {{day}}.",
  "We are probably": "Wir haben vermutlich",
  "You can thus reach us": "Sie erreichen uns daher",
  "+49 221 790 067 34": "(+49) 0221 790 067 34",
  "as well as": "als auch",
  "best": "am Besten",
  "by email": "per E-Mail",
  "by phone": "per Telefon",
  "closed": "geschlossen",
  "hello@ganserDOTconsulting": "hallo@ganser.consulting",
  "local holiday": "Feiertag",
  "open": "geöffnet",
  "sowohl": "sowohl"
}
</i18n>

<i18n locale="en">
{
  "Contact Us": "Contact Us",
  "At our German head office, …": "At our German head office, it is currently {{time}} on a {{day}}.",
  "We are probably": "We are probably",
  "You can thus reach us": "You can thus reach us",
  "+49 221 790 067 34": "+49 221 790 067 34",
  "as well as": "as well as",
  "best": "best",
  "by email": "by email",
  "by phone": "by phone",
  "closed": "closed",
  "hello@ganserDOTconsulting": "hello@ganser.consulting",
  "local holiday": "local holiday",
  "open": "open",
  "sowohl": ""
}
</i18n>
