<template>
  <b-container
    class="bg-white html-container shadow py-3"
    :class="cssClasses"
    :fluid="fluid"
  >
    <b-row>
      <!-- eslint-disable vue/no-v-html -->
      <b-col
        @click="click"
        v-html="html"
      />
      <!-- eslint-enable vue/no-v-html -->
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'HtmlContainer',

  props: {
    cssClasses: {
      required: true,
      type: Array,
    },
    fluid: {
      required: true,
      type: Boolean,
    },
    html: {
      required: true,
      type: String,
    },
  },

  methods: {
    click(ev) {
      if (!ev.target.href) return true
      if (ev.target.origin !== window.location.origin) return true

      this.$router.push(ev.target.pathname)
      ev.preventDefault()
    },
  },
}
</script>

<style lang="scss" scoped>
.html-container {
  &.no-shadow {
    box-shadow: none !important;
  }
}

.html-container ::v-deep {
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #ff4f00 !important;
    font-family: Raleway;
    font-weight: 500;
    margin: 3rem 0 .5rem 0;

    & + h3 { margin-top: .5rem; }
    & + h4 { margin-top: .5rem; }
    & + h5 { margin-top: .5rem; }
    & + h6 { margin-top: .5rem; }
  }

  h2, h3 {
    font-weight: 400;
    margin: .75rem 0 1.8rem 0;
  }

  dd,
  dt,
  li,
  p {
    font-size: 1.1rem;
  }

  dd {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1.2rem;
  }

  .row-main-content {
    h4 {
      font-family: inherit;
      font-size: 1.1rem;
      font-weight: 700;
      margin-top: 0;
    }
  }

  .sidebar-heading {
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 0;
    text-align: right;
  }

  .technology-list {
    a {
      color: #000;
    }

    a:hover {
      color: #ff4f00;
      text-decoration: underline;
      text-decoration-color: #ff4f00;
    }

    ul {
      padding-left: .5rem;

      li {
        margin-bottom: 1rem;
      }
    }
  }

  ul {
    max-width: 50rem;
    padding-left: 1.5rem;

    li {
      border-left: 1px solid #ff4f00;
      list-style: none;
      margin-bottom: 1.5rem;
      padding: 0 0 0 .6rem !important;
    }
  }
  div.card-body ul {
    padding-left: .1rem;
  }
}
</style>
