import Vue from 'vue'

import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faAward as falAward,
  faBalanceScale as falBalanceScale,
  faChartLine as falChartLine,
  faHandshakeAlt as falHandshakeAlt,
} from '@fortawesome/pro-light-svg-icons'

import {
  faEnvelopeOpenText as fasEnvelopeOpenText,
  faPhoneVolume as fasPhoneVolume,
} from '@fortawesome/pro-solid-svg-icons'

library.add(falAward)
library.add(falBalanceScale)
library.add(falChartLine)
library.add(falHandshakeAlt)

library.add(fasEnvelopeOpenText)
library.add(fasPhoneVolume)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
