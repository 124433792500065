<template>
  <b-navbar-nav>
    <b-navbar-brand
      class="d-lg-none"
      style="outline: none;"
      :to="'/'"
      @click="$emit('navigated')"
    >
      <b-img
        alt="ganser.consulting GmbH"
        :src="require('../assets/Logo-Horizontal-White-Registered-Trademark-200x50-1.png')"
        style="margin: .75rem 0 .5rem 1rem; max-height: 45px; max-width: 95%;"
      />
    </b-navbar-brand>
    <app-navbar-menu-item
      v-for="rootMenuItem in rootMenuItems"
      :key="rootMenuItem.id"
      :all-menu-items="menuItems"
      :menu-item="rootMenuItem"
      @navigated="$emit('navigated')"
    />
  </b-navbar-nav>
</template>

<script>
import AppNavbarMenuItem from './AppNavbarMenuItem.vue'

export default {
  name: 'AppNavbarMenu',

  components: {
    AppNavbarMenuItem,
  },

  props: {
    language: {
      required: true,
      type: Object,
    },
    menu: {
      required: true,
      type: Object,
    },
  },

  computed: {
    menuItems() {
      return this.menu.menuItems
        .filter(menuItem => menuItem.page.language === this.language.code)
        .sort((menuItemA, menuItemB) => menuItemA.position - menuItemB.position)
    },
    rootMenuItems() {
      return this.menuItems.filter(menuItem => !menuItem.parentId)
    },
  },
}
</script>
