<template>
  <b-container
    class="py-2"
    fluid
  >
    <b-row class="pb-3 pb-lg-0">
      <b-col
        class="text-center text-md-left"
        cols="12"
        md="8"
      >
        © Copyright 2019 - {{ currentYear }} ganser.consulting GmbH.
      </b-col>

      <b-col
        class="text-center text-md-right"
        cols="12"
        md="4"
      >
        All rights reserved.
      </b-col>
    </b-row>

    <b-row>
      <b-col class="text-center">
        <a
          class="credits text-light"
          href="https://ganser.consulting"
          title="made with love by ganser.consulting GmbH in Cologne, Germany"
        >
          made with
          <abbr
            class="heart text-decoration-none"
            style="cursor: inherit"
            title="love"
          >
            ❤
          </abbr>
          by
          <img
            alt="ganser.consulting GmbH"
            :src="require('../assets/credits/ganser-consulting.svg')"
            title="ganser.consulting GmbH"
          >
          in
          <img
            alt="Cologne, Germany"
            :src="require('../assets/credits/cologne-cathedral.svg')"
            title="Cologne, Germany"
          >
        </a>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'AppFooterCredits',

  props: {
    language: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      currentYear: new Date().getFullYear(),
    }
  },
}
</script>

<style scoped lang="scss">
.container-fluid {
  background-color: #FF4F00;

  .credits {
    opacity: .25;
    transition: opacity .4s ease-out, transform .8s ease-out;

    &:hover {
      opacity: 1;
      text-decoration: none;

      .heart {
        display: inline-block;
        animation: .5s ease-in-out infinite alternate pulse;
      }
    }

    img {
      height: 1.3rem;
      vertical-align: text-bottom;
    }
  }
}

@keyframes pulse {
  from { transform: scale(1); }
  to { transform: scale(1.5); }
}
</style>
