<template>
  <b-container
    class="py-3"
    fluid
  >
    <b-row>
      <b-col
        class="mb-3"
        cols="6"
        lg="3"
        md="4"
        xl="2"
      >
        <b-nav
          class="mb-3"
          vertical
        >
          <b-nav-item
            v-for="supportedLanguage in supportedLanguages"
            :key="supportedLanguage.code"
            class="language-nav"
            :hreflang="supportedLanguage.code"
            link-classes="text-light pl-1"
            :to="languageHref(supportedLanguage.code)"
          >
            <img
              class="lang-flag-icon mr-2"
              :src="require(`../assets/flags/${supportedLanguage.code}.svg`)"
              :title="supportedLanguage.name"
            >
            {{ supportedLanguage.name }}
          </b-nav-item>
        </b-nav>

        <div class="h-card">
          <p class="p-name d-block font-weight-bold">
            ganser.consulting GmbH
          </p>

          <p class="h-adr">
            <span class="p-street-address d-block">Frankenwerft 5</span>
            <span class="p-postal-code">50667</span>
            &nbsp;
            <span class="p-locality">{{ $t('Cologne') }}</span>
            <span v-if="language.code !== 'de'">,</span>
            <abbr
              class="p-region d-none"
              :title="$t('North Rhine-Westphalia')"
            >
              NRW
            </abbr>
            <span
              class="p-country-name"
              :class="{ 'd-none': language.code === 'de' }"
            >
              {{ $t('Germany') }}
            </span>
          </p>

          <p>
            <a
              class="u-url u-uid d-block text-light"
              href="https://ganser.consulting"
            >
              https://ganser.consulting
            </a>
            <a
              class="u-email d-block text-light"
              :href="`mailto:${$t('hello@ganser.consulting')}`"
            >
              {{ $t('hello@ganser.consulting') }}
            </a>
            <a
              class="p-tel d-block text-light"
              href="tel:+4922179006734"
            >
              {{ $t('+49 221 790 067 34') }}
            </a>
          </p>
        </div>

        <b-link
          href="https://www.onepercentfortheplanet.org"
          rel="noopener"
          target="_blank"
        >
          <b-img-lazy
            :alt="$t('1% for the Planet Member')"
            class="one-percent-for-the-planet-logo"
            src="~/../assets/one-percent-for-the-planet-logo.svg"
            :title="$t('We are a proud member of 1% for the Planet')"
          />
        </b-link>
      </b-col>

      <b-col
        v-if="$apollo.loading"
        class="mb-3"
        cols="6"
        lg="3"
        md="4"
        xl="2"
      >
        <b-spinner
          :label="$t('Loading')"
          small
          type="grow"
          variant="light"
        />
      </b-col>

      <b-col
        v-for="rootMenuItem in rootMenuItems"
        :key="rootMenuItem.id"
        class="mb-3"
        cols="6"
        lg="3"
        md="4"
        xl="2"
      >
        <app-footer-submenu
          :all-menu-items="menuItems"
          :menu-item="rootMenuItem"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import gql from 'graphql-tag'
import AppFooterSubmenu from './AppFooterSubmenu.vue'

export default {
  name: 'AppFooterMenu',

  components: {
    AppFooterSubmenu,
  },

  props: {
    language: {
      required: true,
      type: Object,
    },
    page: {
      default: null,
      type: Object,
    },
    supportedLanguages: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      menu: null,
    }
  },

  apollo: {
    menu: {
      query: gql`
        query GetMenu($id: String!) {
          menu(id: $id) {
            menuItems {
              id
              label
              link
              parentId
              position

              page {
                fullPath
                language
              }
            }
          }
        }
      `,
      result({ data }) {
        this.menuLoaded(data.menu)
      },
      variables: { id: 'footer' },
    },
  },

  computed: {
    menuItems() {
      return ((this.menu && this.menu.menuItems) || [])
        .filter(menuItem => menuItem.page.language === this.language.code)
        .sort((menuItemA, menuItemB) => menuItemA.position - menuItemB.position)
    },
    pageTranslations() {
      if (!this.page) return {}

      return Object.fromEntries(this.page.translations.map(pageTranslation =>
        [pageTranslation.language, pageTranslation.fullPath],
      ))
    },
    rootMenuItems() {
      return this.menuItems.filter(menuItem => !menuItem.parentId)
    },
  },

  methods: {
    languageHref(languageCode) {
      if (this.page && this.page.language === languageCode) {
        return this.page.fullPath
      } else if (this.pageTranslations[languageCode]) {
        return this.pageTranslations[languageCode]
      } else {
        return `/${languageCode}`
      }
    },
    menuLoaded(menu) {
      this.menu = menu
      this.$emit('hydrated')
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.container-fluid {
  background-color: rgba(63, 63, 63, .9);

  img.one-percent-for-the-planet-logo {
    max-width: 100%;
    width: 7rem;
  }
}

.language-nav a.nav-link {
  align-items: center;
  display: flex;
}
</style>

<i18n locale="de">
{
  "+49 221 790 067 34": "(+49) 0221 790 067 34",
  "1% for the Planet Member": "„Ein Prozent für den Planeten“ Mitglied",
  "Cologne": "Köln",
  "Germany": "Deutschland",
  "North Rhine-Westphalia": "Nordrhein-Westfalen",
  "We are a proud member of 1% for the Planet": "Wir sind stolzes Mitglied bei „Ein Prozent für den Planeten“",
  "hello@ganser.consulting": "hallo@ganser.consulting"
}
</i18n>

<i18n locale="en">
{
  "+49 221 790 067 34": "+49 221 790 067 34",
  "1% for the Planet Member": "\"1% for the Planet\" Member",
  "Cologne": "Cologne",
  "Germany": "Germany",
  "North Rhine-Westphalia": "North Rhine-Westphalia",
  "We are a proud member of 1% for the Planet": "We are a proud member of \"1% for the Planet\"",
  "hello@ganser.consulting": "hello@ganser.consulting"
}
</i18n>
