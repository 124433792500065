<template>
  <div>
    <b-nav-item-dropdown
      v-if="hasChildMenuItems"
      :text="menuItem.label"
    >
      <b-dropdown-item
        v-for="childMenuItem in childMenuItems"
        :key="childMenuItem.page.id"
        :to="childMenuItem.page.fullPath"
        @click="$emit('navigated')"
      >
        {{ childMenuItem.label }}
      </b-dropdown-item>
    </b-nav-item-dropdown>

    <b-nav-item
      v-else
      :to="menuItem.page.fullPath"
      @click="$emit('navigated')"
    >
      {{ menuItem.label }}
    </b-nav-item>
  </div>
</template>

<script>
export default {
  name: 'AppNavbarMenuItem',

  props: {
    allMenuItems: {
      required: true,
      type: Array,
    },
    menuItem: {
      required: true,
      type: Object,
    },
  },

  computed: {
    childMenuItems() {
      return this.allMenuItems
        .filter(allMenuItem => allMenuItem.parentId === this.menuItem.id)
    },
    hasChildMenuItems() {
      return this.childMenuItems.length > 0
    },
  },
}
</script>

<style scoped lang="scss">
.navbar-dark .navbar-nav .dropdown-menu .dropdown-item {
  color: #000;
  font-weight: normal;
}
</style>
