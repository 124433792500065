import Vue from 'vue'

/* eslint-disable import/first */
import './plugins/bootstrap-vue'
import './plugins/fontawesome'
import 'core-js/stable'
import 'mutationobserver-shim'
import 'regenerator-runtime/runtime'
import App from './App.vue'
import VueI18Next from '@panter/vue-i18next'
import i18next from 'i18next'
import locales from './locales'
import router from './router'
import store from './store'

import { createProvider } from './vue-apollo'
/* eslint-enable import/first */

Vue.config.productionTip = false

Vue.use(VueI18Next)

i18next.init({
  lng: 'en',
  resources: {
    de: { translation: locales.de },
    en: { translation: locales.en },
  },
})

const i18n = new VueI18Next(i18next)

const app = new Vue({
  i18n,
  router,
  store,
  apolloProvider: createProvider(),
  data: () => ({ referrer: null, prerendering: !!window.__PRERENDER_INJECTED }),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
  render: h => h(App),
})

document.addEventListener('DOMContentLoaded', function() {
  app.$mount('#app')
})
